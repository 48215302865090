<template>
  <v-row>
    <v-col cols="12">
      <delete-dialog @refetch-data="fetchOrders" :Id="orderTemp.Id" v-model="isDialogDelete"></delete-dialog>
    <complain-dialog
      @refetch-data="fetchOrders"
      v-model="isActive"
      :Order="complainTemp"
    ></complain-dialog>
    <complain-branch-dialog
      @refetch-data="fetchOrders"
      v-model="isComplainBranchActive"
      :Order="ComplainList"
    ></complain-branch-dialog>
      <!-- <v-row class="mb-5">
        <v-col v-for="(total, i) in OrderTotalLocal" :key="i" cols="12" sm="6" md="3">
          <v-card>
            <v-card-text class="d-flex align-center justify-space-between pa-4" >
              <div>
                <h2 class="font-weight-semibold mb-1" v-if="total.title == 'Count'">
                  {{ total.total - cancelCount }}
                </h2>
                <h2 class="font-weight-semibold mb-1" v-if="total.title =='Total Amount'">
                  {{ (total.total - canceltotal).toFixed(2) }}
                </h2>

                <span>{{ total.title }}</span>
              </div>
              <v-avatar
                :color="resolveTotalIcon(total.title).color"
                :class="`v-avatar-light-bg ${resolveTotalIcon(total.title).color}--text`"
              >
                <v-icon size="25" :color="resolveTotalIcon(total.title).color" class="rounded-0">
                  {{ resolveTotalIcon(total.title).icon }}
                </v-icon>
              </v-avatar>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
      <v-row class="mb-5">
        <v-col v-for="(total, i) in OrderTotalLocal" :key="i" cols="12" sm="6" md="3">
          <v-card>
            <v-card-text class="d-flex align-center justify-space-between pa-4">
              <!-- <div>
                <h2 class="font-weight-semibold mb-1">
                  {{ total.total }}
                </h2>
                <span>{{ total.title }}</span>
              </div> -->
              <div>

                <h2 class="font-weight-semibold mb-1" v-if="total.title =='Count'">
                  {{ total.total - cancelCount  }}
                </h2>
                <h2 class="font-weight-semibold mb-1" v-else>
                  {{ (total.total).toFixed(2) }}
                </h2>
                <span>{{ total.title }}</span>
              </div>
              <v-avatar
                :color="resolveTotalIcon(total.title).color"
                :class="`v-avatar-light-bg ${resolveTotalIcon(total.title).color}--text`"
              >
                <v-icon size="25" :color="resolveTotalIcon(total.title).color" class="rounded-0">
                  {{ resolveTotalIcon(total.title).icon }}
                </v-icon>
              </v-avatar>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Main Card -->
      <app-card-actions @refresh="fetchOrders">

        <template slot="title">
        <v-badge v-bind:color="ComplainList.length > 0 ? 'error' : 'secondary'" bordered overlap
        :value="ComplainList.length"
          :content="ComplainList.length"
        >
          <v-btn
             icon
             v-bind:color="0 > 0 ? 'error' : 'secondary'"
             @click.stop="isComplainBranchActive = !isComplainBranchActive"
           >
             <v-icon small>
               {{ icons.mdiCommentFlash }}
             </v-icon>
           </v-btn>
         </v-badge>  Orders List </template>
        <!-- search -->
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-spacer></v-spacer>

          <div class="d-flex align-center pb-5">
            <select-restaurant class="mb-6" v-model="restaurntFilter" />
            <select-bransh class="mb-6" v-model="branchFilter" :RestaurantId="restaurntFilter" />
            <v-datetime-picker label="Date From" v-model="searchDateFrom">
              <template slot="dateIcon">
                <v-icon>{{ icons.mdiCalendarRange }}</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>{{ icons.mdiClockOutline }}</v-icon>
              </template></v-datetime-picker
            >
            <v-datetime-picker label="Date To" v-model="searchDateTo">
              <template slot="dateIcon">
                <v-icon>{{ icons.mdiCalendarRange }}</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>{{ icons.mdiClockOutline }}</v-icon>
              </template></v-datetime-picker
            >
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search "
            ></v-text-field>

            <v-select
              v-model="orderSourceFilter"
              label="Order Source"
              :items="[
                { Id: 1, Name: 'CallCenter' },
                { Id: 2, Name: 'RestaurantApp' },
                { Id: 3, Name: 'Talabat' },
                { Id: 4, Name: 'Careem' },
                { Id: 5, Name: 'Other' },
              ]"
              item-text="Name"
              item-value="Name"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
            <v-select
              v-model="statusFilter"
              label="Status"
              :items="$store.state.status['orders']"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </div>
        </v-card-text>

        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="OrderListTable"
          item-key="Id"
          :items-per-page="10"
          :options.sync="options"
          :expanded.sync="expanded"
          show-expand
          :loading="loading"
          :sort-desc="[true]"
          single-expand
        >
          <!-- id -->
          <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>

          <!-- Name -->
          <template #[`item.Name`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
                <span class="text-xs">{{ item.Description }}</span>
              </div>
            </div>
          </template>
          <!-- CreationTime -->
          <template #[`item.CreationTime`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{
                  filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')
                }}</span>
              </div>
            </div>
          </template>
          <!-- status -->
          <template #[`item.Status`]="{ item }">
             <v-chip
              small
              :color="
                ordersNew.find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              "
              :class="`${
                ordersNew.find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              }--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{
                ordersNew.find(obj => {
                  return obj.value == item.Status
                }).title
              }}
            </v-chip>

              <!-- <vc-display-status
            :TableName="'Orders'"
            :Status="item.Status"
            /> -->

          </template>
          <!-- Push -->
          <template #[`item.Push`]="{ item }">
            <v-btn v-if="item.Status == 'pinding'" color="info" small @click="PushNotification(item.BranchId)">Push</v-btn>
            </template>
          <!-- Action -->

          <template #[`item.Action`]="{ item }">
            <div class="demo-space-x">
              <vc-change-status
                v-if="item.Status == 'cancelled'"
                @refetch-data="fetchOrders"
                :ObjectId="item.Id"
                TableName="orders"
                Status="pinding"
                :WithDialog="true"
              />
      <div v-if="item.Status == 'cancelrequest'">
                    <v-row justify="space-around">
                    <v-col cols="auto">
                      <v-dialog
                        transition="dialog-top-transition"
                        max-width="600"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                          >Cancel Request</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <v-toolbar
                              color="primary"
                              dark
                            >Order Cancellation Request</v-toolbar>
                            <v-card-text>
                              <div class="pa-12">
                              <p class="my-1">Name: {{ item.Name }}</p>
                              <p class="mb-1">Payment Type: {{ item.PaymentType }}</p>
                              <p class="mb-1">Order Type: {{ item.OrderType }}</p>
                              </div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <div class="justify-start">
                              <vc-change-status
                              @refetch-data="dialogFitch"
                              :ObjectId="item.Id"
                              TableName="orders"
                              Status="pinding"
                              :WithDialog="false"
                            />
                              </div>
                              <div class="justify-end">
                            <vc-change-status
                              @refetch-data="dialogFitch"
                              :ObjectId="item.Id"
                              TableName="orders"
                              Status="cancelled"
                              :WithDialog="false"
                            />
                              </div>
                              <!-- <v-btn
                                text
                                @click="dialog.value = false"
                              >Close</v-btn> -->
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  </div>
              <v-btn
                v-if="item.Status == 'cancelled'"
                icon
                color="error"
                @click.stop=";(isDialogDelete = !isDialogDelete), (orderTemp = { ...item })"
              >
                <v-icon small>
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
                <!-- <span v-if="userRole === 'DEVELOPER' && item.Status != 'cancelled'">
                 <drawer-print Type="Order" :Data="item"/>
              </span> -->
            </div>
          </template>
               <!-- complain -->
      <template #[`item.Complain`]="{ item }">
        <v-badge v-if="item.Complains.length > item.ComplainsReplied.length"
          v-bind:color="item.Complains.length > 0 ? 'error' : 'secondary'"
          bordered
          overlap
          :value="item.Complains.length - item.ComplainsReplied.length"
          :content="item.Complains.length - item.ComplainsReplied.length"
        >
          <v-btn
            icon
            v-bind:color="item.Complains.length > 0 ? 'error' : 'secondary'"
            @click.stop=";(isActive = !isActive), (complainTemp = { ...item })"
          >
            <v-icon small>
              {{ icons.mdiCommentFlash }}
            </v-icon>
          </v-btn>
        </v-badge>
        <v-badge
        v-if="item.Complains.length == item.ComplainsReplied.length"
          v-bind:color="item.ComplainsReplied.length > 0 ? 'success' : 'secondary'"
          bordered
          overlap
          :value="item.ComplainsReplied.length"
          :content="item.ComplainsReplied.length"
        >
          <v-btn
            icon
            v-bind:color="item.ComplainsReplied.length > 0 ? 'success' : 'secondary'"
            @click.stop=";(isActive = !isActive), (complainTemp = { ...item })"
          >
            <v-icon small>
              {{ icons.mdiCommentFlash }}
            </v-icon>
          </v-btn>
        </v-badge>
      </template>
          <!-- expandad data -->
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length / 2">
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col lg="6" md="4" sm="4" cols="4">
                      <span class="d-flex justify-start"><h3>Order Items</h3></span>
                    </v-col>
                    <v-col lg="6" md="8" sm="8" cols="8"> </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="mt-1 mb-1"></v-divider>

                <v-row v-for="(orderTransation, i) in item.OrderTransations" :key="i">
                  <v-col lg="7" cols="7"
                    >{{ orderTransation.Name }} ({{orderTransation.TotalPrice}}$)
                    <br />
                     <div >
                    <span v-for="(Addon, i) in orderTransation.Addon2" :key="i"
                      >Addon : {{ Addon.Name }} - ({{ Addon.Price }})<br
                    /></span>
                    </div>
                    <span
                      ></span>
                    <span v-if="orderTransation.Description != ''"
                      >Note : {{ orderTransation.Description }} <br
                    /></span>
                  </v-col>
                  <v-col lg="2" cols="2">
                    {{ orderTransation.Qty }}
                  </v-col>
                  <v-col>
                    {{ (parseFloat(orderTransation.TotalPrice) * orderTransation.Qty).toFixed(3) }}
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-1"></v-divider>
                <v-row>
                  <v-col lg="6" md="4" sm="4" cols="4">
                    <span class="justify-end text-lg-h6 ms-1">Subtotal</span>
                  </v-col>
                  <v-col lg="6" md="8" sm="8" cols="8">
                    <span class="d-flex justify-end mb-6">
                      {{
                        item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)

                        .toFixed(3)
                      }}
                      JOD
                    </span>
                  </v-col></v-row>
                <div v-if="item.OrderPromos !=''">
                <v-divider class="mt-1 mb-1"></v-divider>
                 <v-row v-if="item.PromoCodePercentage >0" >
                  <v-col lg="7" cols="7">
                    <p class="mb-1">PromoCodePercentage:</p>
                  </v-col>
                  <v-col lg="2" cols="2">
                    <p class="mb-1">{{ (item.PromoCodePercentage)*100 }}%</p>
                  </v-col>
                  <v-col lg="2" cols="2">
                    <p class="mb-1">{{
                    (item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)
                        * item.PromoCodePercentage
                        ).toFixed(3) }}
                        </p>
                  </v-col>
                </v-row>
                <v-row v-if="item.PromoCodeAmount != 0">
                  <v-col lg="9" cols="9">
                    <p  class="mb-1">PromoCodeAmount:</p>
                  </v-col>
                  <v-col lg="2" cols="2">
                    <p  class="mb-1">{{ item.PromoCodeAmount }}</p>
                  </v-col>
                </v-row>
              <v-row>
                  <v-col lg="6" md="4" sm="4" cols="4">
                    <span class="justify-end text-lg-h6 ms-1">Total</span>
                  </v-col>
                  <v-col lg="6" md="8" sm="8" cols="8">
                    <span class="d-flex justify-end mb-6">
                      {{
                        (((item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)
                        )+item.PromoCodeAmount)-
                        (item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)
                        * item.PromoCodePercentage
                        )).toFixed(3)
                      }}
                      JOD
                    </span>
                  </v-col></v-row>
                </div>
<v-divider class="mt-1 mb-1"></v-divider>
<div v-for="i in item.OrderPromos">
                 <p  class="mb-1">
                  Promo: {{ i.Name }}
                 </p>
                 </div>
                 <v-divider class="mt-1 mb-1"></v-divider>
                <p v-if="item.Description != null" class="mb-1">Description: {{ item.Description }}</p>
                <p v-if="item.TaxAmount > 0" class="mb-1">Tax Amount: {{ item.TaxAmount }} JD</p>
                <p class="mb-1">DeliveryPrice: {{ item.DeliveryPrice.toFixed(3) }} JD</p>
                <p class="justify-end text-lg-h6 mb-3 mt-3 ms-1">TotalAmount: {{ item.TotalAmount.toFixed(3) }} JD</p>
              </v-card>
            </td>
            <td :colspan="headers.length / 2">
              <v-row>
                     <v-col lg="10" md="4" sm="4" cols="10">
                     <span class="d-flex justify-end align-center  mb-6">
                      <print-component Type="Order" :Data="item" />
                      </span>
                    </v-col>
                  </v-row>
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col lg="12" md="4" sm="4" cols="4">
                      <span class="d-flex justify-start"><h3>Customer & Address</h3></span>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="mt-1 mb-1"></v-divider>
                <p class="my-1">Name: {{ item.Name }}</p>
                <p class="mb-1">Payment Type: {{ item.PaymentType }}</p>
                <p class="mb-1">Order Type: {{ item.OrderType }}</p>
                <p v-if="item.ScheduleTimeOrder > '2020-01-01 02:00:00.0000000'" class="mb-1">
                  Schedule Time Order: {{ filter.parseTime(item.ScheduleTimeOrder, '{y}-{m}-{d} {h}:{i}') }}
                </p>
                <p class="mb-1">Delivery Type: {{ item.DeliveryType }}</p>
                <p v-if="item.DeliveryCompany != null" class="mb-1">Delivery Company: {{ item.DeliveryCompany }}</p>
                <p class="mb-1">Order Source: {{ item.OrderSource }}</p>
                <p v-if="item.OrderSourceRefNumber != null" class="mb-1">
                  Order Source Ref Number: {{ item.OrderSourceRefNumber }}
                </p>
                <p v-if="item.City != null" class="mb-1">City: {{ item.City }}</p>
                <p v-if="item.BranshAddress != null" class="mb-1">Bransh Address: {{ item.BranshAddress }}</p>
                <p v-if="item.AddressDetail != null" class="mb-1">Address Details: {{ item.AddressDetail }}</p>
                <p v-if="item.BuildingNo != null" class="mb-1">Building No: {{ item.BuildingNo }}</p>
                <p v-if="item.StreetNo != null" class="mb-1">Street Name: {{ item.StreetNo }}</p>
              </v-card>
              <br />
                <v-col class="d-flex justify-end" lg="6" md="8" sm="8" cols="8">
                  <vc-change-status
                  v-if="item.Status != 'cancelrequest' && item.Status != 'cancelled' "
                   @refetch-data="fetchOrders"
                    :ObjectId="item.Id"
                    TableName="orders"
                    Status="cancelled"
                    :WithDialog="true"
                  />

                </v-col>
            </td>
          </template>
        </v-data-table>
      </app-card-actions>
    </v-col></v-row
  >
</template>

<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import DrawerPrint from '@/components/vc-print/DrawerPrint.vue'
import PrintComponent from '@/components/vc-print/PrintComponent.vue'
import pushSubscription from '@/components/vc-push/pushSubscription'
import VcChangeStatus from '@/components/vc-status/vc-change-status.vue'
import VcDisplayStatus from '@/components/vc-status/vc-display-status.vue'
import SelectBransh from '@/views/Branch/SelectBransh.vue'
import SelectRestaurant from '@/views/Menu/Restaurant/SelectRestaurant.vue'
import { NotifyAsync } from '@core/api/Chat.js'
import { parseTime } from '@core/utils/filter'
import { mdiCalendarRange, mdiClockOutline, mdiCommentFlash, mdiDeleteOutline } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import Pusher from 'pusher-js'
import ComplainBranchDialog from './ComplainBranchDialog.vue'
import ComplainDialog from './ComplainDialog.vue'
import DeleteDialog from './DeleteDialog.vue'
import useOrderList from './useOrderList'

// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  components: {
    VcChangeStatus,
    VcDisplayStatus,
    PrintComponent,
    AppCardActions,
    SelectRestaurant,
    SelectBransh,
    DeleteDialog,
    DrawerPrint,
    ComplainDialog,
    ComplainBranchDialog,
  },

  setup() {
    const messages = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData && userData.role ? userData.role : null
    const PushNotification = BrId => {
      NotifyAsync({ BranchId: BrId })
    }
    const orderTemp = ref({ Id: 0 })
    const isActive = ref(false)
    const isComplainBranchActive = ref(false)
    const complainTemp = ref({ Id: 0 })
    const isDialogDelete = ref(false)
    const dialog = ref(false)
    const { checkUrl } = pushSubscription()
    const {
      total,
      cancelCount,
      canceltotal,
      ComplainList,
      fetchOrders,
      OrderListTable,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      totalOrderListTable,
      loading,
      expanded,
      searchDateFrom,
      searchDateTo,
      restaurntFilter,
      branchFilter,
      orderSourceFilter,
      OrderTotalLocal,
      resolveTotalIcon,
    } = useOrderList()

    addEventListener('focus', event => {
      fetchOrders()
      //PlaySound()
    })
    const ordersNew = [
      { title: 'Cancelled', value: 'cancelled', variant: 'error' },
      { title: 'Pinding', value: 'pinding', variant: 'warning' },
      { title: 'Accepted', value: 'accept', variant: 'success' },
      { title: 'Finished', value: 'finished', variant: 'primary' },
      { title: 'Cancelled Request', value: 'cancelrequest', variant: 'error' },
    ]
    const dialogFitch = () => {
      dialog.value = false
      fetchOrders()
    }
    const channelSubscribe = () => {
      let result = checkUrl()
      Pusher.logToConsole = true
      const pusher = new Pusher('01b4d5c8507d46578a54', {
        cluster: 'ap2',
      })

      if (result == false) {
        pusher.disconnect()
      } else {
        const channel = pusher.subscribe('my-channel')
        channel.bind('my-event', data => {
          messages.value.push(data)
          fetchOrders()
        })
      }
    }
    onMounted(() => {
      channelSubscribe()
    })
    return {
      channelSubscribe,
      checkUrl,
      ordersNew,
      userData,
      userRole,
      total,
      messages,
      PushNotification,
      cancelCount,
      canceltotal,
      dialogFitch,
      dialog,
      isComplainBranchActive,
      ComplainList,
      isActive,
      complainTemp,
      isDialogDelete,
      orderTemp,
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalOrderListTable,
      OrderListTable,
      loading,
      expanded,
      fetchOrders,
      searchDateFrom,
      searchDateTo,
      restaurntFilter,
      branchFilter,
      orderSourceFilter,
      OrderTotalLocal,
      resolveTotalIcon,
      filter: { parseTime },
      icons: {
        mdiClockOutline,
        mdiCalendarRange,
        mdiDeleteOutline,
        mdiCommentFlash,
      },
    }
  },
}
</script>
