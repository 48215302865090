<template>
  <div>
    <v-tooltip bottom >
      <template v-slot:activator="{ on, tooltip }">
         <v-btn small fab v-on="on" color="info" @click="Print()" v-bind="tooltip">
                <v-icon dark>
                  {{ icons.mdiPrinter }}
                </v-icon>
              </v-btn>
      </template>
      <span>Without delivery<br /> "بدون سعر التوصيل"</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
         Sorry, try later..
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <img id="qr_code" style="display: none" />
    <iframe id="ifrmPrint" style="display: none"></iframe>
  </div>
</template>
<script>
//import GeneralReceipt from '@/assets/receipt/generalReceipt'
import GeneralReceipt from '@/@core/report/Template/generalReceipt';
import Visualization from '@core/report/Visualization.js';
import { mdiClose, mdiPrinter } from '@mdi/js';
import { onMounted, ref } from '@vue/composition-api';

export default {
  props: {
    Data: {
      type: Object,
      required: true,
    },
    Type: { type: String, required: true },
  },
  setup(props) {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const generalReport = ref({
      Id: 0,
      Name: 'GeneralReceipt',
      Html: GeneralReceipt,
    })
    onMounted(() => {
      document.getElementById('ifrmPrint').src = ''
      //    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      //   device.value = true
      // }
      if (props.Type == 'Order') {
        console.log('dataaa', props.Data)
        if (props.Data.Description == null) {
          props.Data.Description = '/'
        }
        if (props.Data.BranshAddress == null) {
          props.Data.BranshAddress = '/'
        }
        if (props.Data.StreetNo == null) {
          props.Data.StreetNo = '/'
        }
        if (props.Data.BuildingNo == null) {
          props.Data.BuildingNo = '/'
        }
        if (props.Data.AddressDetail == null) {
          props.Data.AddressDetail = '/'
        }
        let Cost = ref(0)
        //let Percentage = ref(0)
        Cost.value = props.Data.OrderTransations.reduce((prev, cur) => {
          return prev + parseFloat(cur.TotalPrice) * cur.Qty
        }, 0).toFixed(3)
        props.Data.Cost = Cost.value

        props.Data.Discount =
          props.Data.OrderTransations.reduce((prev, cur) => {
            return prev + parseFloat(cur.TotalPrice) * cur.Qty
          }, 0) +
          props.Data.PromoCodeAmount -
          props.Data.OrderTransations.reduce((prev, cur) => {
            return prev + parseFloat(cur.TotalPrice) * cur.Qty
          }, 0) *
            props.Data.PromoCodePercentage -
          Cost.value
        props.Data.OrderTransations.forEach((item, index) => {
          let Arr = ref([''])
          if (item.Addon.length) {
            item.Addon.forEach(i => {
              let x =i.Name + '<br />';
              Arr.value.push(x)
            })
            if (item.Description) {
              Arr.value.push('<br />' + item.Description)
            }
            item.Addon = Arr.value
          } else {
            if (item.Addon) {
            if (item.Description) {
              Arr.value.push('<br />' + item.Description)
              }
              item.Addon = Arr.value
            }
          }
          console.log('item.Addon', item.Addon)
          console.log('Arr.value', Arr.value)
        })
        generalReport.value.Html = Visualization(props.Data, generalReport.value.Html, 'Set')
      } else {
        generalReport.value.Html = Visualization(props.Data, generalReport.value.Html, 'Set')
      }
    })
    const dialog = ref(false)
    const Print = item => {
      console.log('printing data', props.Data)
      console.log('userData.RestaurantId', userData.RestaurantId)
      try {
        var oIframe = document.getElementById('ifrmPrint')
        var oDoc = oIframe.contentWindow || oIframe.contentDocument
        console.log(oDoc)
        if (oDoc.document) oDoc = oDoc.document
        console.log('generalReport com', generalReport.value.Name)
        // const template = generalReport.value
        // oDoc.write('<head><title>title</title>')
        // oDoc.write('</head>< onload="this.focus({preventScroll:false}); this.print();">')
        oDoc.write(generalReport.value.Html)
        oDoc.close()
      } catch (e) {
        dialog.value = true
      }
    }
    return {
      generalReport,
      Print,
      Visualization,
      dialog,
      icons: {
        mdiPrinter,
        mdiClose,
      },
    }
  },
}
</script>
