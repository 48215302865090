<template>
  <v-row>
    <v-col cols="12">
      <branch-order />
    </v-col>
  </v-row>
</template>

<script>
import BranchOrder from './Components/BranchOrder.vue'

// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  components: { BranchOrder },
  setup() {},
}
</script>
