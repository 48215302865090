<template>
  <v-dialog @click:outside="resetComplainData()" style="height: 1200px" width="800px" :value="isActive" @input="val => $emit('update:is-active', val)">
  <v-card class="transition-fast-in-fast-out" style="height: 100%">
  <v-card-title>
          <span class="text-big cursor-pointer"> Complaint : {{ Order.Id }} </span>
          <v-spacer></v-spacer>
        </v-card-title>
    <v-data-table
    height = "230"
    fixed-header
    :headers="tableColumns"
    :items="Order.Complains"
    item-key="Id"
    hide-default-footer
    :items-per-page="-1"
    @click:row="rowdbclick"
    >
    calculate-widths: true
      <!-- CreationTime -->
      <template #[`item.CreationTime`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary font-weight-semibold text-truncate">{{
              filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')
            }}</span>
          </div>
        </div>
      </template>
      <template #[`item.AdminReply`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary font-weight-semibold text-truncate">
               <v-chip
               v-if="isreplied(item.AdminReply)"
           class="ma-2"
           color="success"
         outlined
           text-color="secondary"
         ><v-avatar left>
            <v-icon color="success">{{icons.mdiCheckboxMarkedCircle}}</v-icon>
      </v-avatar>
      Done
    </v-chip>
     <v-chip
         v-if="!isreplied(item.AdminReply)"
         class="ma-2"
         text-color="secondary"
         color="error"
         outlined
         ><v-avatar left>
            <v-icon color="error">{{icons.mdiCloseCircle }}</v-icon>
      </v-avatar>
      Done
    </v-chip>
            </span></div>
        </div>
      </template>
    </v-data-table>
    </v-card>
     <v-divider class="mt-6 mb-6"></v-divider>
     <div v-if="isSelected">
     <v-card class="transition-fast-in-fast-out pt-5" style="height: 200px" >
      <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
        <v-card-text>
            <v-row>
            <v-col cols="11">
               <span class="text-big cursor-pointer">Complaint Reply</span>
            </v-col>
            <v-col cols="1">
               <v-btn icon small color="secondary" @click="check = false">
                <v-icon dark>
        {{icons.mdiPencil}}
      </v-icon> </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3"></v-divider>
          <v-textarea
            v-model="ComplainData.AdminReply"
            class="mx-2"
            :rules="[validators.required]"
            rows="1"
            hide-details="auto"
            outlined
            label="Admin Reply"
            v-bind:disabled="check"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions></v-form
      >
    </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { CreateReply } from '@/@core/api/Complain'
import { parseTime } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import { mdiCheckboxMarkedCircle, mdiCloseCircle, mdiPencil } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default {

  model: {
    prop: 'isActive',
    event: 'update:is-active',
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    Order: {
      type: Object,
      required: true,
    },

  },
    watch: {
      Order(val) {
        this.ComplainData.OrderId = val.Id
      },
    },
  setup(props, { emit }) {
    const check = ref (false)
    const isSelected = ref(false)
    const blankData = {
      Id: undefined,
      ComplainCategory: '',
      Comment: '',
      OrderId: undefined,
      BranshId: undefined,
      AdminReply:'',
    }
    const tableColumns = [
      { text: 'id', value: 'Id', sortable: false },
      { text: 'Complain Category', value: 'ComplainCategory', sortable: false },
      { text: 'Comment', value: 'Comment', sortable: false },
      { text: 'Creation Time', value: 'CreationTime', sortable: false },
      { text: 'Admin Reply', value: 'AdminReply', sortable: false, },
    ]
    const valid = ref(false)
    const rowclick = ref(0)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }
    const ComplainData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetComplainData = () => {
      ComplainData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      isSelected.value = false
      emit('update:is-active', false)
    }
    const close = () => {
      resetComplainData()
    }
      const rowdbclick = (event, val) => {
         if (ComplainData.value.Id == val.item.Id && rowclick.value == 0) {
            rowclick.value += 1
            isSelected.value = false
          }
        else {
            isSelected.value = true
            rowclick.value = 0
            ComplainData.value = val.item
             if (ComplainData.value.AdminReply == null) { check.value = false }
           else {check.value = true}
          }
  }
     const onSubmit = () => {
       if (valid.value) {
        //  ComplainData.value.Id = props.Order.Complains[0].Id
         CreateReply(ComplainData.value).then(() => {
           emit('refetch-data')
           isSelected.value = false
           emit('update:is-active', false)
         })
         resetComplainData()
       } else {
         validate()
       }
     }
     const isreplied = (item) => {
      if (item == null || item == '')
      return false
      else { return true }
     }
     watch(
    [
      ComplainData,
    ],
    () => {
      ComplainData.value
    },
  )



    return {
      check,
      isSelected,
      tableColumns,
      ComplainData,
      onSubmit,
      close,
      validate,
      resetComplainData,
      rowdbclick,
      rowclick,
      isreplied,
      form,
      validators: { required },
      valid,
      filter: { parseTime },
      icons: {
        mdiPencil,
        mdiCheckboxMarkedCircle,
        mdiCloseCircle,
      },
    }
  },
}
</script>
