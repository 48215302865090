<template>
  <div>
    <!-- <v-chip
       small
       :color="
         NewStatusArray.find(obj => {
           return obj.value == Status
         }).variant || 'primary'
       "
       :class="`${
         NewStatusArray.find(obj => {
           return obj.value == Status
         }).variant || 'primary'
       }--text`"
       class="v-chip-light-bg font-weight-semibold text-capitalize"
     >
       {{
        NewStatusArray.find(obj => {
           return obj.value == Status
         }).title
       }}
     </v-chip> -->
     <v-chip
       small
       :color="`${NewStatusArray.variant}`"
       :class="`${NewStatusArray.variant}--text`"
       class="v-chip-light-bg font-weight-semibold text-capitalize"
     >
       {{
        NewStatusArray.title
       }}
     </v-chip>
     </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { onMounted } from 'vue-demi'

export default {
  props: {
    TableName: { type: String, required: true },
    Status: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const NewStatusArray = ref([])
    const ordersNew = [
    { title: 'Cancelled', value: 'cancelled', variant: 'error' },
    { title: 'Pinding', value: 'pinding', variant: 'warning' },
    { title: 'Accepted', value: 'accept', variant: 'success' },
    { title: 'Finished', value: 'finished', variant: 'primary' },
    { title: 'Cancelled Request', value: 'cancelrequest', variant: 'error' },
  ]
    onMounted(() => {
      if (props.TableName == 'Orders'){
        let f = ordersNew.find(obj => obj.value == props.Status
         )
         console.log('ffffffffffff', f)
      NewStatusArray.value = f

      console.log('NewStatusArray', NewStatusArray.value)
      }
//       if( props.NewStatus != null ){

//         NewStatusArray.value = props.NewStatus.slice()
//         let Old = $store.state.status[TableName]
//         var found = false;
//         for(var i = 0; i < Old.length; i++) {
//           if ( old[i].includes((NewStatusArray.value.value)) )
//           for(var i = 0; i < Old.length; i++) {
//           if (NewStatusArray.value[i].title == 'Magenic') {
//           found = true;
//         break;
//     }
// }
//           if (NewStatusArray.value[i].title == 'Magenic') {

//           found = true;

//         break;
//     }
// }
//       }
    })

    return {
      ordersNew,
      NewStatusArray,
     }
  },
}
</script>
